import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from '@components/global';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        room: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "heroimage" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper id="start">
        <Container style={{ position: 'relative' }}>
          <Art fluid={data.room.childImageSharp.fluid}></Art>
          <TranslucentTextBox></TranslucentTextBox>
          <StyledText>
            <StyledTitle>
              Ihr zuverlässiger Partner wenn es um die eigenen vier Wände geht
            </StyledTitle>
          </StyledText>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.white.regular};
`;

const Art = styled(Img)`
  width: 100%;
  height: 100vh;
  margin: 0;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: auto;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    height: auto;
  }
`;

const TranslucentTextBox = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0px;
  position: absolute;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
`;

const StyledText = styled.div`
  width: 60%;
  position: absolute;
  bottom: 0px;
  text-align: left;
  transform: translate(15%, -200%);
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
  font-size: 32px;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 40%);
    text-align: center;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 40%);
    text-align: center;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 100%;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 40%);
    text-align: center;
  }
`;
const StyledTitle = styled.h3`
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
  font-size: 32px;
  margin-top: 0px;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 24px;
    line-height: 20px;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    font-size: 18px;
    line-height: 20px;
  }
`;
export default Header;
