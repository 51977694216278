import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import styled from 'styled-components';

import Header from '@sections/Header';
import Services from '@common/Services';
import Footer from '@sections/Footer';
import Contact from '@sections/Contact';

const IndexPage = () => (
  <Layout>
    <StyledNavbar />
    <Header />
    <Services />
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;

const StyledNavbar = styled(Navbar)`
  position: absolute;
`;
